@import "../../utils/colors.scss";

.home_container {
  // text-align: center;
  background-color: $primary-gray2;
  min-height: 90vh;
  
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  padding-left: 8vw;
  padding-right: 8vw;
  color: white;
  .home-intro_container {
    display: flex;
    flex-direction: column;
    .intro-header {
      display: flex;
      flex-direction: column;
      .intro-header-title {
        display: flex;
        flex-direction: row;
        font-weight: 400 !important;
        font-size: xx-large;
        justify-content: baseline;
        // vertical-align: bottom;
        // height: 0px;
        .Typewriter {
          display: flex;
          flex-direction: column;
          height: 105px;
          align-items: center;
          justify-content: flex-end;
          font-size: 95%;
          .Typewriter__cursor {
            display: none;
          }
        }
      }
      .intro-header-subtitle {
        font-size: 60%;
        font-weight: 600;
      }
    }
  
    .intro-body {
      font-size: medium;
      max-width: 50vw;
      .bold_text {
        color: $coral;
      }
    }
  }
  .home-content_container {
    padding-left: 5vw;
    display: flex;
  }

  a:link {
    text-decoration: none !important;
  }
  a:hover {
    text-decoration: underline !important;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    // transform: translateX(-200px);
  }
  50% {
    opacity: 0.3
  }
  100% {
    opacity: 1;
  }
}

