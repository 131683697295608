.resume-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    min-height: 100vh;
    .resume-download-container {
        display: flex;
        flex: row;
        justify-content: center;
        padding-top: 30px;
    }
    .resume-table {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding-top: 20px;
        padding-bottom: 20px;
        .resume-workplace-details {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: start;
            .resume-workplace-details_name {
                font-weight: 600;
                font-size: 110%;
            }
            .resume-workplace-details_location {
                font-style: italic;
                font-size: 90%;
            }
            .resume-workplace-details_date {
                font-style: italic;
            }           
        }
        .resume-workplace-title {
            font-weight: 600;
            font-size: 110%;
        }
        .resume-workplace-responsibilities {
            font-family: Verdana, Geneva, Tahoma, sans-serif;
            ul {
                padding: 0px 0px 0px 10px;
                text-align: justify;
                li {
                    padding: 0 0 20px 0;
                }
                // list-style-type: none;
            }
        }
        ::-webkit-scrollbar {
            width: 10px;
            height: 10px;
          }
          
        ::-webkit-scrollbar-thumb {
            background: rgba(90, 90, 90);
        }
        
        ::-webkit-scrollbar-track {
            background: rgba(0, 0, 0, 0.2);
        }
    }
}